import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { executeApi, useApi } from '../../composables/useApi'
import { useMemberSessionStore } from '../../stores/memberSessionStore'

export const useMenuStateStore = defineStore('useMenuStateStore', () => {
  const api = useApi()
  const menuStatus = ref(false)

  const { isLoggedIn } = storeToRefs(useMemberSessionStore())

  const getMenuStatus = async () => {

    return

    if (!isLoggedIn.value ) return
    try {
      await executeApi(async () => {
        const { data } = await api.memberData.getMenuStatus()

        menuStatus.value = data
      })
    } catch (e) {
      console.log('getMenuStatus error')
      console.log(e)
    }
  }

  const setMenuStatus = async (state: boolean) => {
    menuStatus.value = state

    return
    if (!isLoggedIn) return
    try {
      await executeApi(async () => {
        await api.memberData.setMenuStatus({ status: state })
      })
    } catch (e) {
      console.log(e)
    }
    getMenuStatus()
  }

  return { menuStatus, getMenuStatus, setMenuStatus }
})
